.slick-prev:before,
.slick-next:before {
  color: #1662A7;
  font-size: 35px;
} 
.slick-dots li button:before {
    color: #1662A7;
  }
  .slick-dots li.slick-active button:before {
    color:#1662A7;
   
} 

.testimonials .testimonials_scrollbar {
  height: 100px;
  overflow-y: scroll;
}

.testimonials .testimonials_scrollbar::-webkit-scrollbar {
  width: 2px;
}

.testimonials .testimonials_scrollbar::-webkit-scrollbar-track {
  background-color: transperent;
}

.testimonials .testimonials_scrollbar::-webkit-scrollbar-thumb {
  background-color: transperent;
}